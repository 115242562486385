import React, { useState } from 'react';
import {
    AppBar,
    Box,
    IconButton,
    Menu,
    MenuItem,
    Grid,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/APM_LOGO_FULL.png'; // Adjust the path as needed

const ApmAppBar: React.FC = () => {
    const location = useLocation(); // Get the current route path
    const navigate = useNavigate(); // For navigation
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // Menu anchor state

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget); // Set the anchor element for the menu
    };

    const handleMenuClose = () => {
        setAnchorEl(null); // Close the menu
    };

    const handleNavigate = (path: string) => {
        navigate(path); // Navigate to the desired path
        handleMenuClose(); // Close the menu after navigation
    };

    return (
        <AppBar
            position="fixed" // Keeps the AppBar fixed at the top
            sx={{
                bgcolor: 'background.paper',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                zIndex: 1201, // Ensure it stays above other content
                padding: 2, // Add padding for layout
            }}
        >
            <Grid container>
                {/* First Column: Hamburger Menu */}
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start', // Align hamburger menu to the bottom
                        paddingLeft: 1, // Add padding to the left
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleMenuOpen}
                        sx={{
                            color: 'text.primary', // Use theme text color
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Grid>

                {/* Second Column: Logo */}
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        component="img"
                        src={logo}
                        alt="APM Logo"
                        sx={{
                            height: '125px', // Adjust height for a consistent look
                            width: 'auto',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>

                {/* Third Column: Empty */}
                <Grid
                    item
                    xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                />
            </Grid>

            {/* Menu Items */}
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                    mt: 1,
                    '& .MuiPaper-root': {
                        bgcolor: '#181818', // Dark gray background for the menu
                        color: '#ffffff', // White text for menu items
                    },
                }}
            >
                {location.pathname !== '/' && (
                    <MenuItem
                        onClick={() => handleNavigate('/')}
                        sx={{
                            '&:hover': {
                                bgcolor: '#444444', // Slightly lighter gray on hover
                            },
                        }}
                    >
                        <Typography>Home</Typography>
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => handleNavigate('/about')}
                    sx={{
                        '&:hover': {
                            bgcolor: '#444444', // Slightly lighter gray on hover
                        },
                    }}
                >
                    <Typography>About</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigate('/privacy-policy')}
                    sx={{
                        '&:hover': {
                            bgcolor: '#444444', // Slightly lighter gray on hover
                        },
                    }}
                >
                    <Typography>Privacy Policy</Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => handleNavigate('/delete-account')}
                    sx={{
                        '&:hover': {
                            bgcolor: '#444444', // Slightly lighter gray on hover
                        },
                    }}
                >
                    <Typography>Delete Account</Typography>
                </MenuItem>
            </Menu>
        </AppBar>
    );
};

export default ApmAppBar;
